<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/zhaopzbsy/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>


                    <div style="width:160px;">
                        <el-select v-model="where.F_IN_JID_ID" placeholder="请选择基地" @change="selectChange()">
                            <el-option label="请选择基地" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['jid']" :label="v.F_VR_JIDMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div style="width:160px;">
                        <el-select v-model="where.F_IN_YINGQ_ID" placeholder="请选择营期" @change="selectChange()">
                            <el-option label="请选择营期" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingq']" :label="v.F_IN_NAME" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div style="width:160px;">
                        <el-select v-model="where.F_IN_YINGQQX_ID" placeholder="请选择期数" @change="selectChange()">
                            <el-option label="请选择期数" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['yingqx']" :label="v.F_VR_QISHU" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div style="width:160px;">
                        <el-select v-model="where.F_IN_YINGDBJ_ID" placeholder="请选择班级" @change="selectChange()">
                            <el-option label="请选择班级" :value="0"></el-option>
                            <el-option v-for="(v,k) in list['banj']" :label="v.F_VR_BANJMC" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </div>

                    <div><el-button icon="el-icon-search" @click="get_adv_list()">条件筛选</el-button></div>

                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="info" @selection-change="handleSelectionChange" >
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column prop="F_IN_ID" label="#"  width="70px"></el-table-column>

                    <el-table-column prop="F_IN_JIDMC" label="基地"></el-table-column>
                    <el-table-column prop="F_IN_NAME" label="营期"></el-table-column>
                    <el-table-column prop="F_VR_QISHU" label="营期期限"></el-table-column>
                    <el-table-column prop="F_VR_BANJMC" label="班级"></el-table-column>


                    <el-table-column label="封面">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><img :src="scope.row.F_VR_FENHGM||require('@/assets/default_pic.png')" width="50px" height="50px"></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.adv_title }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="视频" width="140px">
                        <video-player
                                ref="videoPlayer" :playsinline="true"  :options="playerOptions">
                        </video-player>
                    </el-table-column>
                    <el-table-column prop="F_VR_SHIPJJ" label="视频简介"></el-table-column>
                    <el-table-column prop="F_IN_LIULRC" label="浏览人次"></el-table-column>
                    <el-table-column prop="F_VR_BANJJJ" label="班级简介"></el-table-column>
                    <el-table-column prop="adv_start" label="创建时间">
                        <template slot-scope="scope">
                            <div v-if="scope.row.F_IN_CHUANGJSJ<=0"> - </div>
                            <div v-else>{{scope.row.F_IN_CHUANGJSJ|formatDate}}</div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'zhaopzbsy_form',params:{id:scope.row.F_IN_ID}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>

                </el-table>
                <div class="admin_table_main_pagination">
                    <el-pagination @current-change="current_change" background layout="prev, pager, next,jumper,total" :total="total_data" :page-size="page_size" :current-page="current_page"></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css'
export default {
    components: {videoPlayer},
    props: {},
    data() {
      return {
          list:[],
          total_data:0, // 总条数
          page_size:20,
          current_page:1,
          select_id:'',
          adv_position_id:0,
          where:{
          },
          info:[],
          playerOptions:{
              //播放速度
              playbackRates:[0.5,1.0,1.5,2.0],
              //如果true,浏览器准备好时开始回放.
              autoplay:false,
              //默认情况下将会消除任何音频
              muted:false,
              //导致视频一结束就重新开始
              loop:false,
              //建议浏览器在<video>加载元素后是否应该开始下载视频
              preload:'auto',
              language:'zh-CN',
              aspectRatio:'16:9',
              fluid:true,
              sources:[{
                  //类型
                  type:"video/mp4",
                  //url地址
                  // src:'http://wechat.cloudliveshop.com/Uploads/upload/1610248596.mp4'
              }],
              poster:'',
              //允许覆盖Video.js无法播放媒体源时显示的默认信息
              notSupportedMessage:'此视频暂无法播放，请稍后再试',
              controlBar:{
                  timeDivider:true,
                  durationDisplay:true,
                  remainingTimeDisplay:false,
                  //全屏按钮
                  fullscreenToggler:true
              }
          }
      };
    },
    watch: {},
    computed: {
        player(){
            return this.$refs.videoPlayer.player
        }
    },
    methods: {
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.F_IN_ID);
            });
            this.select_id = ids.join(',');
        },
        get_adv_list:function(){
            this.where.page = this.current_page;
            this.$get(this.$api.getZhaopzbsyList,this.where).then(res=>{
                this.page_size = res.data.info.per_page;
                this.total_data = res.data.info.total;
                this.current_page = res.data.info.current_page;
                this.list = res.data;
                this.info = res.data.info.data;
                // 临时数组对象C
                var arrayC = []
                // 遍历数组B，以id的值作为数组的key，name的值作为数组的value,存放到临时数组arrayC
                for(let i = 0; i < res.data.info.data.length; i ++) {
                    // 为arrayC添加名称为arrayB[i].id的属性字段，值等于arrayB[i].name
                    console.log('取值')
                    console.log(res.data.info.data[i].F_VR_SHIP)
                    arrayC[i] = res.data.info.data[i].F_VR_SHIP;
                    console.log('取值')
                }
                var newarr = arrayC.map(item => ({
                    src:item
                }));
                console.log('取值1')
                console.log(arrayC)
                console.log(newarr)
                console.log('取值1')
                this.playerOptions['sources']= newarr;
            })

        },
        // 删除处理
        del:function(F_IN_ID){
            if(this.$isEmpty(F_IN_ID)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delZhaopzbsy,{F_IN_ID:F_IN_ID}).then(res=>{
                if(res.code == 200){
                    this.get_adv_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        },
        current_change:function(e){
            this.current_page = e;
            this.get_adv_list();
        },
    },
    created() {
        this.get_adv_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>